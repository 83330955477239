let isFirst = true
export default defineNuxtRouteMiddleware((to, from) => {
     try {
        if(isFirst){
          isFirst = false
          return
        }
        window._hmt = window._hmt || []
        window._hmt.push(['_trackPageview', to.fullPath])
      } catch (e) { /* empty */ }
  })