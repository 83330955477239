// since `.js, .ts` files are not included by default,
// the following comment tells UnoCSS to force scan this file (to pick the logo icon).
// @unocss-include

export default {
  name: 'Hibachi Now',
  description: 'Now you can bring the hibachi experience home to your own backyard with Hibachi Now. You set the table for your guests and we’ll bring the rest.',
  author: 'Hibachi Now',
  slogon: 'Top Rated Hibachi Catering Experience in Florida',
  url: 'https://hibachinow.com',
  defaultLocale: 'en', // default
  identity: {
    type: 'Organization',
  } as any,
  twitter: '@vuedesigner',
  trailingSlash: false, // default
  titleSeparator: '|', // default
  mobile: '9548499286',
  bookingPath: '/locations',
  email: 'gohibachinow@gmail.com',
  sns: {
    twitter: 'https://www.x.com/',
    // facebook: 'https://www.facebook.com/',
    instagram: 'https://www.instagram.com/yhibachinow',     
  },

  nav: [
    { text: 'Home', link: '/', type: 'primary', icon: 'i-mdi-home' },
    { text: 'Locations', link: '/locations', type: 'primary', icon: 'i-mdi-home' },
    { text: 'Gallery', link: '/gallery', type: 'primary', icon: 'i-mdi-home' },
    { text: 'FAQ', link: '/faq', type: 'primary', icon: 'i-mdi-home' },
  ],
  faqs: [
    {
      question: 'What should you prepare?',
       answer: 'We bring the Chef, Cooking Setup, and Food. You provide Tables and Chairs for your party, positioned around where our chef will set up to cook. Also provide your guests Eating Utensils, Plates and salad bowls, and Beverages of choice.Please note we cook outside only.'
    },
    {
      question: 'How much does your service cost?',
       answer: 'Our Service has a base price of $50 per adult and $25 per child, with a $500 minimum spend. A travel fee may apply to certain areas. Suggested gratuity for the chef is 20% of total bill. Our services are cash only. There is no deposit required, all payments are due the day of the event.'
    },
    {
      question: 'What time will the chef arrive?',
       answer: 'The chef will arrive approximately 10 minutes prior to reservation time. Our set up process is seamless and only takes a few minutes.'
    },
    {
      question: 'Do you set up tables and chairs?',
       answer: 'No, we don’t set up your tables and chairs.  We provide the chef, grill, food, sake and of course, great entertainment! Customers will provide utensils, drinks, and table setups.'
    },
    {
      question: 'Do you cook inside homes?',
       answer: 'No, we only cook outside for safety and liability reasons. Our experience is open to terraces, balconies, and under awnings.'
    },
    {
      question: 'Do you cook with nuts or sesame products?',
       answer: 'No, our food does not contain any nuts or sesame products. Please notify the booking agent of any other food allergy a customer may have.'
    },
    {
      question: 'Can you accommodate Gluten Free?',
       answer: 'Yes, we have serviced many gluten free customers. We ask that you bring your favorite gluten free soy sauce and teriyaki sauce for the chef to cook your portion separate!'
    },
  ]
}
