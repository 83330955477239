<script setup lang="ts">
import { usaMobileDisplayFormat } from '~~/app/utils/index'

import type { Region } from '~~/types/regions'
const region = defineProps<Region>()

</script>
<template>
  <div class="relative rounded overflow-hidden">
    <img :src="region.image" :alt="region.name">
    <div
      class="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
      <div class="uppercase">{{ region.name }}</div>

      <NuxtLink
        :to="`/booking/${region.id}`"
        class="px-6 py-2 my-4 min-w-[120px] text-center text-lg text-primary-500 rounded-full focus:ring bg-white" size="sm">
        BOOK NOW
      </NuxtLink>

      <div class="text-sm">OR CALL TO BOOK
        <NuxtLink :to="`tel:${region.mobile}`" class="underline">
          {{ usaMobileDisplayFormat(region.mobile) }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
