<script setup lang="ts">
const photos = ref([])

onMounted(() => {
  for (let i = 1; i <= 8; i++) {
    photos.value.push({ src: `/images/gallery/x${i}.jpg` })
  }
})

</script>

<template>
  <SectionHeader title="Top Mobile Hibachi Catering" desc="Get a Glimpse of Our Catering Events" />

  <section class="flex justify-center">
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4 p-4 place-items-center">
      <div v-for="(photo, i) in photos" :key="i"  >
         <!-- <NuxtLink
        :to="`/gallery`" >
        <img class="hover:opacity-75 " :src="photo.src">

      </NuxtLink> -->

      <!-- <Image :src="photo.src" alt="Image" preview /> -->
      <Image alt="Image" preview>
    <template #previewicon>
        <i class="pi pi-search"/>
    </template>
    <template #image>
        <img :src="photo.src" alt="image"  class="img">
    </template>
    <template #preview>
        <img :src="photo.src" alt="preview"  class="p-2" >
    </template>
</Image>
      </div>
    </div>
  </section>
</template>


<style>
  .img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* transition: opacity 0.3s; */
  }
</style>
