<script setup lang="ts">
import { reviews } from '~~/db.json'

const reviewsTmp = reviews.slice(0, 9)

</script>

<template>
    <SectionHeader title="Customer Reviews" desc="We have some fans." />
    <section class="container mx-auto px-4">
        <NowCarousel :value="reviewsTmp">
            <template #item="slotProps">
                <UserReview :review="slotProps.data"/>
            </template>
        </NowCarousel>
    </section>
   
    
</template>
<style scoped>
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .scrollbar-hide::-webkit-scrollbar {
        display: none;  /* Chrome, Safari and Opera */
    }

    @media (max-width: 1535px) {
        .container {
            max-width: none;
        }
    }
</style>