<script setup lang="ts">
// import { heroImageUrl } from '@/utils/hero'
import site from '~~/site'

const {
  bookingPath
} = site

const heroImageUrl = '/images/hero/b4.jpg'

const heroVideoUrl = '/images/hero/banner3.mp4'
// const heroVideoUrl = '/images/hero/fire.mp4'

const props = defineProps({
    img: {
      type: String,
      default(){
        return heroImageUrl
      },
    },
    title: {
      type: String,
      default: "Top Rated Hibachi Catering Experience in Florida",
    },
    subTitle: {
      type: String,
      default: "Book Your Backyard Hibachi Party Today!",
    },
    goto: {
      type: String,
      default: ''
    }
  })

const url = computed(() => {
    return props.goto || bookingPath
})


</script>
<template>
  <div class="relative bg-gradient-to-r bg-neutral-600 h-screen text-white overflow-hidden bg-center bg-cover bg-no-repeat">
    <!-- 蒙版 -->
    <div class="absolute inset-0 bg-black opacity-40">
      <img :src="img" :alt="title" class="object-cover object-center w-full h-full video" >
      <!-- <video class="object-cover object-center w-full h-full" autoplay playsinline muted loop :poster="heroImageUrl" :src="heroVideoUrl" /> -->
    </div>

    <div class="relative z-10 flex flex-col justify-center items-center h-full text-center mx-4">
      <!-- <h2 class="text-2xl mb-8 font-bold">{{ site.name }}</h2> -->
      <p class="text-4xl text-center md:text-6xl font-bold leading-tight mb-4">{{ title }}</p>
      <p class="text-2xl font-medium md:text-3xl mb-8">{{ subTitle }}</p>
      <!-- <p class="text-lg md:text-xl  mb-8">@ Florida</p> -->
      <BaseButton class="mt-10" size="xl" label="BOOK NOW" :to="`${url}`">
        <div class="inline-block mx-12 ">BOOK NOW</div>
      </BaseButton>
    </div>
  </div>
</template>
<style>
/* video::-webkit-media-controls-enclosure{
    display: none !important;
} */

.vsc-controller {
  display: none !important;
}

</style>
