import { default as _91id_931AQuEMssAkMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/booking/[id].vue?macro=true";
import { default as faqYUurdFDSTlMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/faq.vue?macro=true";
import { default as galleryo68MKiyIhNMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/gallery.vue?macro=true";
import { default as _91id_93FOS8pjXrFTMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/hibachi-catering/[id].vue?macro=true";
import { default as index0xzH8Ty8xbMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/index.vue?macro=true";
import { default as locations_45servicedVdIlGAFaNBMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations-serviced.vue?macro=true";
import { default as locationsKmFw6n8qRHMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations.vue?macro=true";
import { default as menueNKtSlYuiDMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/menu.vue?macro=true";
export default [
  {
    name: "booking-id",
    path: "/booking/:id()",
    meta: _91id_931AQuEMssAkMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqYUurdFDSTlMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryo68MKiyIhNMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "hibachi-catering-id",
    path: "/hibachi-catering/:id()",
    meta: _91id_93FOS8pjXrFTMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/hibachi-catering/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index0xzH8Ty8xbMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations-serviced",
    path: "/locations-serviced",
    meta: locations_45servicedVdIlGAFaNBMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations-serviced.vue").then(m => m.default || m)
  },
  {
    name: "locations",
    path: "/locations",
    meta: locationsKmFw6n8qRHMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: "menu",
    path: "/menu",
    meta: menueNKtSlYuiDMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/menu.vue").then(m => m.default || m)
  }
]