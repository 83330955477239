<script setup lang="ts">
import { ref, computed } from 'vue'

const { review } = defineProps({
  review: {
    type: Object,
    required: true,
  },
})

const isExpanded = ref(false)
const textRef = ref<HTMLElement | null>(null)
const isClamped = ref(false)

const checkClamp = () => {
  if (textRef.value) {
    isClamped.value = textRef.value.scrollHeight > textRef.value.clientHeight
  }
}

</script>
<template>
  <div class="p-4 border-gray-100 border-2 aspect-auto rounded-lg shadow-gray-600/10">
    <div class="flex gap-4 items-start">
      <img class="w-12 h-12 rounded-full" :src="review.reviewerAvatar" alt="user avatar" width="400" height="400"
        loading="lazy">
      <div class="flex-1 flex justify-between items-start">
        <div>
          <h6 class="text-lg font-medium text-gray-700 line-clamp-1">{{ review.reviewer }}</h6>
          <!-- <p class="text-sm text-gray-500">Car Enthusiast</p> -->
          <UserRating :rating="review.rating" />
        </div>
        <a target="_parent" class="text-blue-500 hover:text-blue-600 ml-4">
          <!-- <a target="_parent" :href="review.url" class="text-blue-500 hover:text-blue-600 ml-4"> -->
          <!-- <i class="fab" :class="'fa-' + review.supplier.toLowerCase()" /> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M11.99 13.9v-3.72h9.36c.14.63.25 1.22.25 2.05c0 5.71-3.83 9.77-9.6 9.77c-5.52 0-10-4.48-10-10S6.48 2 12 2c2.7 0 4.96.99 6.69 2.61l-2.84 2.76c-.72-.68-1.98-1.48-3.85-1.48c-3.31 0-6.01 2.75-6.01 6.12s2.7 6.12 6.01 6.12c3.83 0 5.24-2.65 5.5-4.22h-5.51z" />
          </svg> </a>
      </div>
    </div>
    <div class="mt-8 min-h-20">
      <!-- <p class="line-clamp-2">{{ review.text }}</p> -->
      <p 
        ref="textRef"
        class="text-sm leading-[1.2] md:text-base"
        :class="!isExpanded && 'line-clamp-2'"
        @vue:mounted="checkClamp"
        @vue:updated="checkClamp"
      >
        {{ review.text }}
      </p>

      <a 
        v-if="isClamped || isExpanded"
        type="button" 
        class="text-neutral-500 hover:text-neutral-600 mt-2 text-sm underline"
        @click="isExpanded = !isExpanded"
      >
        {{ isExpanded ? 'Show Less' : 'Read More' }}
      </a>
    </div>
  </div>
</template>
<style scoped></style>
